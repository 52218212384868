import React, { Component} from 'react';
import Logo from '../Assets/Images/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import SnakeGame from './SnakeGame';
import GalagaGame from './GalagaGame';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            showSnakeGame: false,
            showGalagaGame: false
        };
    }

    toggleSnakeGame = () => {
        this.setState(prevState => ({
            showSnakeGame: !prevState.showSnakeGame,
            showGalagaGame: false
        }));
    }

    toggleGalagaGame = () => {
        this.setState(prevState => ({
            showGalagaGame: !prevState.showGalagaGame,
            showSnakeGame: false
        }));
    }

    render() {
        const buttonStyle = {
            width: '100%',
            padding: '20px',
            fontSize: '24px',
            backgroundColor: '#ddd',
            border: 'none',
            cursor: 'pointer',
            marginBottom: '20px',
            transition: 'background-color 0.3s',
            color: '#191919',
            fontWeight: 'bold',
        };

        return(
            <header id="header" className="site-header header-style-1">
                <nav className="navigation navbar navbar-default">
                    <div className="container-fluid">
                        <div className="navbar-header">
                            <button type="button" className="open-btn" onClick={() => this.setState({isOpen: true})}>
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </button>
                            <a className="navbar-brand" href="index.html">
                                <img src={Logo} alt="" />
                            </a>
                        </div>
                        <div id="navbar" className={ this.state.isOpen ? "navbar-collapse navigation-holder active" : "navbar-collapse navigation-holder"}>
                            <button className="close-navbar" onClick={() => this.setState({isOpen: false})}>
                                <FontAwesomeIcon icon={faTimes} />    
                            </button>
                            <button className="close-navbar-2" onClick={() => this.setState({isOpen: false})}>
                                <FontAwesomeIcon icon={faTimes} />
                            </button>
                            <ul className="nav navbar-nav">
                                <li style={{ width: '100%', textAlign: 'center' }}>
                                    {!this.state.showSnakeGame && !this.state.showGalagaGame ? (
                                        <div>
                                            <button 
                                                style={buttonStyle} 
                                                onClick={this.toggleSnakeGame}
                                                onMouseOver={(e) => e.target.style.backgroundColor = '#fff'}
                                                onMouseOut={(e) => e.target.style.backgroundColor = '#ddd'}
                                            >
                                                PLAY SNAKE!
                                            </button>
                                            <button 
                                                style={buttonStyle} 
                                                onClick={this.toggleGalagaGame}
                                                onMouseOver={(e) => e.target.style.backgroundColor = '#fff'}
                                                onMouseOut={(e) => e.target.style.backgroundColor = '#ddd'}
                                            >
                                                PLAY GALAGA!
                                            </button>
                                        </div>
                                    ) : this.state.showSnakeGame ? (
                                        <div>
                                            <button 
                                                style={{...buttonStyle, marginBottom: '40px'}} 
                                                onClick={this.toggleSnakeGame}
                                                onMouseOver={(e) => e.target.style.backgroundColor = '#fff'}
                                                onMouseOut={(e) => e.target.style.backgroundColor = '#ddd'}
                                            >
                                                Close Game
                                            </button>
                                            <SnakeGame />
                                        </div>
                                    ) : (
                                        <div>
                                            <button 
                                                style={{...buttonStyle, marginBottom: '40px'}} 
                                                onClick={this.toggleGalagaGame}
                                                onMouseOver={(e) => e.target.style.backgroundColor = '#fff'}
                                                onMouseOut={(e) => e.target.style.backgroundColor = '#ddd'}
                                            >
                                                Close Game
                                            </button>
                                            <GalagaGame />
                                        </div>
                                    )}
                                </li>
                            </ul>
                        </div>
                        <div className="menu-open-btn-holder">
                            <button className="menu-open-btn" onClick={() => this.setState({isOpen: true})}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </button>
                        </div>
                    </div>
                </nav>
            </header>
        );
    }
}

export default Header;