import React, { useState, useEffect, useCallback } from 'react';

const SnakeGame = () => {
  const [snake, setSnake] = useState([[0, 0]]);
  const [food, setFood] = useState([10, 10]);
  const [direction, setDirection] = useState('RIGHT');
  const [gameOver, setGameOver] = useState(false);
  const [score, setScore] = useState(0);

  const gridSize = 20;
  const cellSize = 20;

  const generateFood = useCallback(() => {
    const newFood = [
      Math.floor(Math.random() * gridSize),
      Math.floor(Math.random() * gridSize)
    ];
    setFood(newFood);
  }, []);

  const moveSnake = useCallback(() => {
    if (gameOver) return;

    const newSnake = [...snake];
    let head = [...newSnake[0]];

    switch (direction) {
      case 'UP':
        head[1] -= 1;
        break;
      case 'DOWN':
        head[1] += 1;
        break;
      case 'LEFT':
        head[0] -= 1;
        break;
      case 'RIGHT':
        head[0] += 1;
        break;
      default:
        break;
    }

    // Check for collisions with walls
    if (head[0] < 0 || head[0] >= gridSize || head[1] < 0 || head[1] >= gridSize) {
      setGameOver(true);
      return;
    }

    // Check for collisions with self
    if (newSnake.some(segment => segment[0] === head[0] && segment[1] === head[1])) {
      setGameOver(true);
      return;
    }

    newSnake.unshift(head);

    // Check if snake ate food
    if (head[0] === food[0] && head[1] === food[1]) {
      setScore(score + 1);
      generateFood();
    } else {
      newSnake.pop();
    }

    setSnake(newSnake);
  }, [snake, direction, food, gameOver, score, generateFood]);

  useEffect(() => {
    const handleKeyPress = (e) => {
      switch (e.key) {
        case 'ArrowUp':
          if (direction !== 'DOWN') setDirection('UP');
          break;
        case 'ArrowDown':
          if (direction !== 'UP') setDirection('DOWN');
          break;
        case 'ArrowLeft':
          if (direction !== 'RIGHT') setDirection('LEFT');
          break;
        case 'ArrowRight':
          if (direction !== 'LEFT') setDirection('RIGHT');
          break;
        default:
          break;
      }
    };

    document.addEventListener('keydown', handleKeyPress);
    const gameInterval = setInterval(moveSnake, 150);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
      clearInterval(gameInterval);
    };
  }, [moveSnake, direction]);

  const resetGame = () => {
    setSnake([[0, 0]]);
    setFood([10, 10]);
    setDirection('RIGHT');
    setGameOver(false);
    setScore(0);
  };

  const gameStyles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px',
      backgroundColor: '#191919',
      color: '#fff',
    },
    grid: {
      display: 'grid',
      gridTemplateColumns: `repeat(${gridSize}, ${cellSize}px)`,
      gap: '1px',
      backgroundColor: '#333',
      border: '2px solid #444',
      margin: '20px 0',
    },
    cell: {
      width: `${cellSize}px`,
      height: `${cellSize}px`,
      backgroundColor: '#191919',
    },
    snake: {
      backgroundColor: '#ddd',
    },
    food: {
      backgroundColor: '#ff4444',
    },
    score: {
      fontSize: '24px',
      marginBottom: '20px',
    },
    gameOver: {
      fontSize: '32px',
      color: '#ff4444',
      marginBottom: '20px',
    },
    button: {
      padding: '10px 20px',
      fontSize: '18px',
      backgroundColor: '#ddd',
      border: 'none',
      cursor: 'pointer',
      marginTop: '20px',
    },
  };

  return (
    <div style={gameStyles.container}>
      <div style={gameStyles.score}>Score: {score}</div>
      {gameOver && (
        <div style={gameStyles.gameOver}>Game Over!</div>
      )}
      <div style={gameStyles.grid}>
        {Array.from({ length: gridSize * gridSize }).map((_, index) => {
          const x = Math.floor(index / gridSize);
          const y = index % gridSize;
          const isSnake = snake.some(segment => segment[0] === y && segment[1] === x);
          const isFood = food[0] === y && food[1] === x;
          return (
            <div
              key={index}
              style={{
                ...gameStyles.cell,
                ...(isSnake && gameStyles.snake),
                ...(isFood && gameStyles.food),
              }}
            />
          );
        })}
      </div>
      {gameOver && (
        <button style={gameStyles.button} onClick={resetGame}>
          Play Again
        </button>
      )}
    </div>
  );
};

export default SnakeGame; 