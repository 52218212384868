import React, { useState, useEffect, useCallback } from 'react';

const GalagaGame = () => {
  const [player, setPlayer] = useState({ x: 200, y: 380 });
  const [bullets, setBullets] = useState([]);
  const [enemies, setEnemies] = useState([]);
  const [gameOver, setGameOver] = useState(false);
  const [score, setScore] = useState(0);
  const [gameStarted, setGameStarted] = useState(false);

  const canvasWidth = 400;
  const canvasHeight = 400;
  const playerWidth = 30;
  const playerHeight = 30;
  const bulletSize = 5;
  const enemySize = 20;

  // Initialize enemies
  const initializeEnemies = useCallback(() => {
    const newEnemies = [];
    for (let i = 0; i < 3; i++) {
      for (let j = 0; j < 6; j++) {
        newEnemies.push({
          x: j * 60 + 50,
          y: i * 50 + 50,
          direction: 1,
        });
      }
    }
    setEnemies(newEnemies);
  }, []);

  // Start game
  const startGame = () => {
    setGameStarted(true);
    setGameOver(false);
    setScore(0);
    setPlayer({ x: 200, y: 380 });
    setBullets([]);
    initializeEnemies();
  };

  // Move player
  const movePlayer = useCallback((direction) => {
    if (!gameStarted || gameOver) return;
    
    setPlayer(prev => {
      const newX = prev.x + direction * 10;
      if (newX >= 0 && newX <= canvasWidth - playerWidth) {
        return { ...prev, x: newX };
      }
      return prev;
    });
  }, [gameStarted, gameOver]);

  // Shoot bullet
  const shoot = useCallback(() => {
    if (!gameStarted || gameOver) return;
    
    setBullets(prev => [...prev, { x: player.x + playerWidth / 2, y: player.y }]);
  }, [gameStarted, gameOver, player]);

  // Update game state
  useEffect(() => {
    if (!gameStarted || gameOver) return;

    const gameLoop = setInterval(() => {
      // Move bullets
      setBullets(prev => {
        return prev
          .map(bullet => ({ ...bullet, y: bullet.y - 5 }))
          .filter(bullet => bullet.y > 0);
      });

      // Move enemies
      setEnemies(prev => {
        let needsDirectionChange = false;
        const updatedEnemies = prev.map(enemy => {
          const newX = enemy.x + enemy.direction * 2;
          if (newX <= 0 || newX >= canvasWidth - enemySize) {
            needsDirectionChange = true;
          }
          return { ...enemy, x: newX };
        });

        if (needsDirectionChange) {
          return updatedEnemies.map(enemy => ({
            ...enemy,
            direction: -enemy.direction,
            y: enemy.y + 20
          }));
        }
        return updatedEnemies;
      });

      // Check collisions
      setBullets(prev => {
        const remainingBullets = [...prev];
        setEnemies(prevEnemies => {
          const remainingEnemies = prevEnemies.filter(enemy => {
            const hitByBullet = remainingBullets.some((bullet, bulletIndex) => {
              if (
                bullet.x >= enemy.x &&
                bullet.x <= enemy.x + enemySize &&
                bullet.y >= enemy.y &&
                bullet.y <= enemy.y + enemySize
              ) {
                remainingBullets.splice(bulletIndex, 1);
                setScore(s => s + 10);
                return true;
              }
              return false;
            });
            return !hitByBullet;
          });

          // Check if enemies reached player
          if (remainingEnemies.some(enemy => enemy.y + enemySize >= player.y)) {
            setGameOver(true);
          }

          return remainingEnemies;
        });
        return remainingBullets;
      });

    }, 50);

    return () => clearInterval(gameLoop);
  }, [gameStarted, gameOver, player.y, initializeEnemies]);

  // Handle keyboard input
  useEffect(() => {
    const handleKeyPress = (e) => {
      switch (e.key) {
        case 'ArrowLeft':
          movePlayer(-1);
          break;
        case 'ArrowRight':
          movePlayer(1);
          break;
        case ' ':
          e.preventDefault();
          shoot();
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [movePlayer, shoot]);

  const gameStyles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px',
      backgroundColor: '#191919',
      color: '#fff',
    },
    canvas: {
      position: 'relative',
      width: `${canvasWidth}px`,
      height: `${canvasHeight}px`,
      backgroundColor: '#000',
      border: '2px solid #444',
      overflow: 'hidden',
    },
    player: {
      position: 'absolute',
      width: `${playerWidth}px`,
      height: `${playerHeight}px`,
      backgroundColor: '#fff',
      clipPath: 'polygon(50% 0%, 100% 100%, 0% 100%)',
      transition: 'left 0.1s',
      left: `${player.x}px`,
      top: `${player.y}px`,
    },
    bullet: {
      position: 'absolute',
      width: `${bulletSize}px`,
      height: `${bulletSize}px`,
      backgroundColor: '#ff0',
      borderRadius: '50%',
    },
    enemy: {
      position: 'absolute',
      width: `${enemySize}px`,
      height: `${enemySize}px`,
      backgroundColor: '#f00',
      clipPath: 'polygon(50% 0%, 100% 100%, 0% 100%)',
      transform: 'rotate(180deg)',
    },
    score: {
      fontSize: '24px',
      marginBottom: '20px',
    },
    gameOver: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      fontSize: '32px',
      color: '#ff4444',
      textAlign: 'center',
      zIndex: 1,
    },
    button: {
      padding: '10px 20px',
      fontSize: '18px',
      backgroundColor: '#ddd',
      color: '#191919',
      border: 'none',
      cursor: 'pointer',
      marginTop: '20px',
    },
    instructions: {
      textAlign: 'center',
      marginTop: '20px',
      fontSize: '14px',
      color: '#888',
    },
  };

  return (
    <div style={gameStyles.container}>
      <div style={gameStyles.score}>Score: {score}</div>
      <div style={gameStyles.canvas}>
        {!gameStarted && !gameOver && (
          <div style={gameStyles.gameOver}>
            <button style={gameStyles.button} onClick={startGame}>
              Start Game
            </button>
          </div>
        )}
        {gameOver && (
          <div style={gameStyles.gameOver}>
            Game Over!
            <br />
            <button style={gameStyles.button} onClick={startGame}>
              Play Again
            </button>
          </div>
        )}
        <div style={gameStyles.player} />
        {bullets.map((bullet, index) => (
          <div
            key={index}
            style={{
              ...gameStyles.bullet,
              left: `${bullet.x}px`,
              top: `${bullet.y}px`,
            }}
          />
        ))}
        {enemies.map((enemy, index) => (
          <div
            key={index}
            style={{
              ...gameStyles.enemy,
              left: `${enemy.x}px`,
              top: `${enemy.y}px`,
            }}
          />
        ))}
      </div>
      <div style={gameStyles.instructions}>
        Use ← → to move | SPACE to shoot
      </div>
    </div>
  );
};

export default GalagaGame; 